// import INSERT_FORM_ANSWER from '@/graphql/mutations/insertFormAnswer.gql'
import SAVE_SURVEY_ANSWERS from '@/graphql/mutations/saveSurveyAnswers.gql'
import SEND_FULL_SURVEY from '@/graphql/mutations/sendFullSurvey.gql'
import { PromiseResolveEnum } from '@/utils/enums'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
// import { FORM_TYPES, SURVEY_IDLE_TIMEOUT } from '@/modules/constants'
import { FORM_TYPES } from '@/modules/constants'
// import UPDATE_FORM_ANSWER from '@/graphql/subscriptions/saveFormAnswer.gql'
import UPDATE_FORM_ANSWER from '@/graphql/mutations/updateFormAnswer.gql'
import INSERT_FORM_ANSWER from '@/graphql/mutations/insertFormAnswer.gql'
import {SAVE_USER_INFO} from '@/graphql/mutations/formFunctions.gql'
import store from '@/store'
import * as Sentry from "@sentry/vue"

const PreSurveyMixins = {
    methods: {
        getNextFormRouteName(isKiosk, goToThankYou, shortName) {
            if (goToThankYou) {
                return isKiosk ? 'Kiosk-thank-you' : shortName ? 'Survey-thank-you' : 'thank-you'
            }
            if (isKiosk) return 'Kiosk-DynamicFormView'
            return shortName ? 'Survey-DynamicFormView' : 'DynamicFormView'
        },
        onLastForm() {
            const isWizard = this.formSet?.formType?.type === FORM_TYPES.Wizard
            const currentForm = this.forms[0]
            // if the form type isn't a wizard then we go straight to the thankyou page for non-external forms
            if (!isWizard) return this.$route.name.toLowerCase().indexOf('dynamicform') > -1

            const idx = this.allForms.findIndex(af => af.formId === currentForm.formId && af.version === currentForm.version)
            return idx === this.allForms.length - 1
        }
    }
}

const FormMixins = {
    methods: {
        getFormAnswerPayload() {
            //making sure we add in the other properties of the saved form answer if we have it
            let payload = {
                ...(this.formAnswer || {}),
                tenantId: this.tenantId,
                formSetId: this.formSetId,
                formTypeId: this.formSet?.formTypeId || null,
                surveyType: this.formSet?.surveyType || null,
                userInfo: {
                    userAgent: navigator?.userAgent,
                    platform: navigator?.userAgent?.platform
                }
            }
            // if we are referred from a different answer, we wantt to save the answer link so we don't lose it
            // otherwise we just set the current formAnswerId to what we got from the database
            if (this.formReferrer) payload.previousFormAnswerId = this.formAnswerId
            else if (this.formAnswerId) payload.formAnswerId = this.formAnswerId

            if (!payload.answer) payload.answer = []
            if (payload.typename) delete payload.typename
            if ('dueDate' in payload) delete payload.dueDate
            if ('createdAt' in payload) delete payload.createdAt
            if ('createdBy' in payload) delete payload.createdBy
            return payload
        },
        async saveForm() {
            if (!this.forms?.length) throw new Error('no forms to save')
            this.disabled = true

            const toSave = this.getFormAnswerPayload()
            console.log('form to save in saveForm', toSave)
            toSave.answer = [...snakecaseKeys(this.forms, { deep: true })]
            return await this.$apollo.mutate({
                mutation: SAVE_SURVEY_ANSWERS,
                variables: {
                    answer: toSave // don't need deep=true as we only want to change the keys
                }
            })
        },
        async saveFormMutation() {
            try {
                let toSave = this.getFormAnswerPayload()
                console.log('===== do we have a form answer?', toSave.formAnswerId)
                const formMutation = toSave.formAnswerId ? UPDATE_FORM_ANSWER : INSERT_FORM_ANSWER
                if (toSave.formAnswerId) {
                    //now we add the current form or update the one already in the payload
                    const currForm = this.forms[0]
                    const idx = this.allForms.findIndex(a => a.formId === currForm.formId && a.version === currForm.version)
                    if (idx === -1) this.allForms.push(currForm)
                    else this.allForms[idx] = currForm
                    toSave.answer = [...snakecaseKeys(this.allForms, { deep: true })]
                } else {
                    toSave.answer = [...snakecaseKeys(this.forms, { deep: true })]
                }

                Sentry.captureMessage(`===== the form answer for formAnswerId: ${toSave.formAnswerId}, with obj: ${JSON.stringify(toSave)}`)
                // if (!toSave.answer?.length) {
                //     Sentry.captureMessage(`===== the form answer for formAnswerId: ${toSave.formAnswerId}, with obj: ${JSON.stringify(toSave)}`)
                // }

                let variables = toSave.formAnswerId ?
                    { ...snakecaseKeys(toSave), status: this.formAnswer.status } :
                    { formAnswer: snakecaseKeys(toSave) }
                // if (variables.formAnswerId) variables = snakecaseKeys(variables)

                // if (!variables.formAnswer?.formAnswerId) delete variables.formAnswer?.formAnswerId
                const response = await this.$apollo.mutate({
                    mutation: formMutation,
                    variables
                })
                const { data: { formAnswer } } = response
                this.formAnswer = { ...camelcaseKeys(formAnswer || {}, { deep: true }) }
                return formAnswer
            } catch (e) {
                console.log('error from saving form', e)
                return {}
            } finally {
                //need to hide save or something at this point
            }
        },
        async saveExternal() {
            this.updateIsSaving(true)
            try {
                const form = await this.saveFormMutation()
                this.formAnswerId = form.formAnswerId
                return form
            } catch (e) {
                console.log('error', e)
            } finally {
                this.disabled = false
                this.updateIsSaving(false)
            }
        },
        async saveQuestionnaire() {
            this.updateIsSaving(true)
            // this.loading = true
            let error = null, emailSent = false
            try {
                const answer = await this.saveFormMutation()
                const savedAnswer = camelcaseKeys(answer, { deep: true })
                // let promise
                this.formAnswerId = savedAnswer.formAnswerId
                emailSent = await this.tempRunSubHandler(savedAnswer.formAnswerId)

                if (!emailSent) error = new Error('Error sending email')
                if (!error) {
                    switch (emailSent) {
                        // we shouldn't have the case where there are no questions, if there aren't, there's a
                        // serious problem
                        case PromiseResolveEnum.NO_FUNC_FOUND:
                            console.log('email not sent, continuing...')
                            break
                        case PromiseResolveEnum.NO_QUESTIONS_FOUND:
                            console.log('no questions found, continuing...')
                            break
                        default:
                            break
                    }
                }

            } catch (e) {
                console.log('something happened', e)
                this.disabled = false
            } finally {
                console.log('saved the form and sending the email (if the email was set).')
                this.disabled = false
                this.updateIsSaving(false)
                // this.loading = false
            }

            if (error) throw error
            return emailSent
        },
        async saveUserInfo() {
            this.saving = true
            // this.loading = true
            try {
                // if (!variables.formAnswer?.formAnswerId) delete variables.formAnswer?.formAnswerId
                const response = await this.$apollo.mutate({
                    mutation: SAVE_USER_INFO,
                    variables: {
                        userInfo: {
                            tenantId: this.tenantId,
                            formAnswerId: this.formAnswerId,
                            userInfo: {
                                userAgent: navigator?.userAgent,
                                platform: navigator?.userAgent?.platform
                            }
                        }
                    }
                })
                const { data: { answer } } = response
                // let's check here to see if the answer on the formAnswer object is blank or not
                if (!answer?.answer?.length) {
                    Sentry.captureMessage(`===== the form answer in the function to save the user info is empty for formAnswerId: ${this.formAnswerId}, with obj: ${JSON.stringify(answer)}`)
                }
                this.formAnswer = {...camelcaseKeys(answer || {}, {deep: true})}
                return this.formAnswer
            } catch (e) {
                console.log('error from saving form', e)
                return {}
            } finally {
                //need to hide save or something at this point
                this.saving = false
            }
        },
        async save() {
            this.validate()
            if (!this.isValid && !this.isExternal && !this.fullSurvey) return Promise.resolve('')

            // we don't need to pass the form as the mixin will pick it up from the scope
            // TODO: make more generic so any form can be saved.... or something to think about
            // for now we assume we only have an external form or a questionnaire - we can add more later
            let resp
            if (this.isExternal) resp = await this.saveExternal()
            else resp = await this.saveQuestionnaire()

            if (this.formAnswerId) await this.saveUserInfo()

            return resp
        },
        // function to do the redirect of the form
        async saveAndSubmit() {
            // first need to show the user if they have finished the form
            this.validate()
            if (!this.isValid) return Promise.reject('Form is invalid')
            this.loading = true
            try {
                await this.save()
                this.redirectToThankYou()
            } catch (e) {
                console.log('error', e)
            } finally {
                this.disabled = false
            }
        },
        /**
         * NOTE: this is used in the config of the form that redirects to this form
         * so the way to use it is in the 'actions' column of the table, add this as the 'action' for the 'next' or 'confirm' button
         * @returns {Promise<never>}
         */
        async saveAndSubmitThankYouQrCode() {
            // first need to show the user if they have finished the form
            this.validate()
            if (!this.isValid) return Promise.reject('Form is invalid')
            this.loading = true
            try {
                await this.save()
                this.redirectToThankYou('npsc-thank-you-qr-code')
            } catch (e) {
                console.log('error', e)
            } finally {
                this.disabled = false
            }
        },
        async saveAndSubmitBasicThankYou() {
            // first need to show the user if they have finished the form
            this.validate()
            if (!this.isValid) return Promise.reject('Form is invalid')
            this.loading = true
            try {
                await this.save()
                let routeName = 'npsc-basic-thank-you'
                if (store.getters.isKiosk) routeName = `kiosk-${routeName}`
                this.redirectToThankYou(routeName)

                // NOTE: the below was only needed for the conference, so add back if required
                // goToUrl('https://www.ultimateq.health/enquiry')
            } catch (e) {
                console.log('error', e)
            } finally {
                this.disabled = false
            }
        },
        async saveAndGoToNext() {
            //idx should be the order of the current form
            if (this.allForms?.length) {
                //TODO: check if this is right, it might not be
                this.formAnswerId = null
                await this.save()
                // now we go to the nextForm and we get the form that's +1 index from the current one (which is the only one in this.forms
                const form = this.forms[0]
                const currFormIdx = this.allForms.findIndex(af => af.formId === form.formId && af.version === form.version)
                if (currFormIdx > -1) this.forms = [this.allForms[currFormIdx + 1]]
            }
        },
        functionCallHandler(func, args) {
            if (!args) args = []
            // first we check if the current component has the function we are looking for
            if (func in this) return this[func](...args)
            else Promise.resolve('no_func_found')
        },
        /**
         * function to run the sub handlers for the form, let's start at the moment by just running the last one
         * cause if we run all of them, we don't know the side effects for now
         *
         * @param answerId
         * @returns {Promise<string>|*}
         */
        async tempRunSubHandler(answerId) {
            //if we are using this mixin, there should always be a form object, make sure there is
            return new Promise((resolve) => {
                if (!this.forms?.length) return resolve('Error: no form object found')

                const form = this.forms[0]
                if (!form?.questions?.length) return resolve(PromiseResolveEnum.NO_QUESTIONS_FOUND)
                const question = form.questions[form.questions.length - 1]
                if (question.onSaveFunc && question.value) {
                    //TODO: make below more generic - maybe a handler to get the question type and what we need to call the handler
                    // or pass the values in the handler itself
                    this.hasPhoneOrEmail = true
                    const result = this.functionCallHandler(question.onSaveFunc, [question.value.email, question.value.mobile, answerId])
                    resolve(result)
                } else {
                    resolve(PromiseResolveEnum.NO_FUNC_FOUND)
                }
            })
        },
        runSubFuncHandlers() {
            //if we are using this mixin, there should always be a form object, make sure there is
            if (!this.form) return Promise.resolve('Error: no form object found')

            const promiseCalls = []
            for (let question of this.form.questions) {
                if (question.onSaveFunc && question.value) {
                    //TODO: make below more generic - maybe a handler to get the question type and what we need to call the handler
                    // or pass the values in the handler itself
                    this.hasPhoneOrEmail = true
                    promiseCalls.push(this.functionCallHandler(question.onSaveFunc, [question.value.email, question.value.mobile]))
                }
            }
            return promiseCalls
        },
        //TODO: use the below to call the promises that we find in the question list
        async sendSurvey(email, phone, formAnswerId) {
            return this.$apollo.mutate({
                mutation: SEND_FULL_SURVEY,
                variables: {
                    email: email,
                    phone: phone?.phone,
                    tenant: this.tenantId,
                    formId: Number(this.formId),
                    formVersion: Number(this.formVersion),
                    formAnswerId: formAnswerId,
                    formSetId: this.formSetId || '',
                    practitioner: this.keywordSearch
                }
            })
        },
        redirectToThankYou(routeName) {
            setTimeout(async (that) => {
                // TODO: remove FSI when and if we ge the new changes in to ignore a specific formSet - as we want the latest published
                const query = { c: that.category, t: that.title, ti: that.tenantId, hpe: that.hasPhoneOrEmail, fs: that.fullSurvey, fsi: that.formSetId, referrer: this.formReferrer, redirectTo: this.redirectTo }
                const name = routeName || (that.isKiosk ? 'Kiosk-thank-you' : that.shortName ? 'Survey-thank-you' : 'thank-you')

                const endpoint = {
                    name: name,
                    query
                }
                const params = { formAnswerId: that.formAnswerId }
                if (that.shortName) params.shortName = that.shortName
                endpoint.params = { ...params }
                await that.$router.push(endpoint)
                that.disabled = false
                that.loading = false
            }, 2000, this)
        }
    }
}

export {
    FormMixins,
    PreSurveyMixins
}
