import {DEBUG} from '@/modules/constants'

const FormBuilderMethods = {
    methods: {
        isComplete(item) {
            let hasSubCompleteSvg
            let questionSets = item.mappedQuestionSets
            if (item.combinedQuestionsAndSets?.length) questionSets = item.combinedQuestionsAndSets

            //before we go and check anything, let's check if this current item depends on a question and if it's actually visible
            // this item could be a questionSet and might not even be rendered
            if (
                (['form_builder_hasura_question_set', 'form_builder_hasura_question'].indexOf(item.typename) > -1) &&
                item.dependsOnQuestionId
            ) {
                const isRendered = this.renderQuestion(item)
                if (!isRendered) return true
            }
            if (questionSets) {
                //NOTE: theses could be questions or questionSets
                for (let subQuestion of questionSets) {
                    hasSubCompleteSvg = this.isComplete(subQuestion)
                    if (!hasSubCompleteSvg) return hasSubCompleteSvg
                }
            }
            // if we get to this point and we have gone through the list of 'combinedQuestionsAndSets' then we can return
            if (item.combinedQuestionsAndSets?.length && hasSubCompleteSvg) return true

            let questions = item.typename === 'form_builder_hasura_question' ? [item] : item.questions
            if (item.combinedQuestionsAndSets?.length) questions = item.combinedQuestionsAndSets.filter(mq => mq.questionId !== null)
            if (!hasSubCompleteSvg && (!questions || questions.length === 0)) return true

            for (let question of questions) {
                // we check if we have 'required' in the rules, if not, it's not required
                const isRequired = question?.config && (question?.config?.rules?.indexOf('required') > -1 || question?.config[':rules']?.indexOf('required'))
                if (!isRequired) continue

                const value = question.value
                const hasRendered = !!this.renderQuestion(item)
                // if the item hasn't been rendered and it's dependent on something, then we can ignore it
                if (!hasRendered && question.dependsOnQuestionId != null) continue
                // if the value we have is an object or an array, we check if the array is empty or the object has a null value
                // for now, the object should only be a phone number so let's look for that
                if (value instanceof Array && !!isRequired && value.length === 0) return false
                else if (value instanceof Object && !!isRequired && !!value?.phone) return false
                else if ((value === '' || value == null) && !!isRequired) return false
            }
            return true
        },
        // TODO: need to consolidate the function in QuestionPanel
        renderQuestion(question) {
            let key = 'questionSetId'
            if (!question[key]) key = 'questionId'
            if (!question[key]) return false
            if (!this.questionMapping) return false

            return this.questionMapping[question[key]]
        },
        getTotalQuestions(questionSets) {
            let total = 0
            for (let questionSet of questionSets) {
                if (!questionSet.mappedQuestionSets) total += questionSet.questions ? questionSet.questions.length : 0
                else total += this.getTotalQuestions(questionSet.mappedQuestionSets)
            }
            return total
        },
        getNumberComplete(questionSets) {
            let total = 0
            for (let questionSet of questionSets) {
                if (!questionSet.mappedQuestionSets) {
                    total += questionSet.questions ? questionSet.questions.filter(q => q.value != null && q.value !== '').length : 0
                }
                else total += this.getNumberComplete(questionSet.mappedQuestionSets)
            }
            return total
        },
        printError(error, explicit) {
            if (DEBUG) console.warn(`[${explicit ? 'EXPLICIT' : 'INEXPLICIT'}] ${error.name}: ${error.message}`)
        },
        formatRuleFunctions(rules) {
            let loadedRules
            if (!rules) return []
            // let's check if we can load the rules
            if (rules && rules.constructor.name.toLowerCase() === 'string') {
                try {
                    loadedRules = JSON.parse(rules)
                } catch (e) {
                    if (e instanceof SyntaxError) {
                        // this.printError(e, true)
                        // // this means that the string might not have the string around the rule name - we need this to be able to apply it
                        rules = rules.replace('[', '').replace(']', '')
                        loadedRules = rules.split(',')
                    } else {
                        this.printError(e, false)
                    }
                }
            }

            if (!loadedRules) loadedRules = rules
            // if we don't find any rules, we just return an empty array
            if (loadedRules.constructor.name.toLowerCase() !== 'array') return []
            // returning the function that we find from the formsMixins - if it's not in this, add it
            return loadedRules.map(r => typeof r === 'string' ? this[r] : r)
        },
        getRulesFromConfig(config) {
            return this.formatRuleFunctions(config.rules || config[':rules'])
        }
    },
}

const FormRules = {
    methods: {
        // we check for empty or null because we want to take into account radio buttons - so can't just use !!value
        required: value => (value != null && value != '') || 'Required.',
        requiredNoText: value => !!value || '',
        min: v => v.length >= 8 || 'Min 8 characters',
        max: v => !v || v.length <= 16 || 'Max 16 characters',
        mobileLength: v => !v || v?.replace(/ /g, '').length === 10 || 'Mobile is not valid',
        postcodeLength: v => !v || v?.length === 4 || 'Postcode is not valid',
        numbersOnly: v => {
            if (!v || !v.trim() || !isNaN(parseInt(v)) && v >= 0) return true
            return 'Value has to be a number'
        },
        timeOnly: v => {
            const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
            
            if (!v || !v.trim() || timeRegex.test(v)) return true
            return 'Value should be in form hh:mm am|pm'
        },
        validEmail: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(v) || 'E-mail is invalid',
        validRadioGroup: v => {
            return !!v || 'Required.'
        }
    }
}

const ALLOWED_KEYCODE = [8, 37, 39, 46]

const FormEvents = {
    methods: {
        timeOnlyEvent(e) {
            const val = e.key
            if (!/[0-9:AaPpMm ]/.test(val) && ALLOWED_KEYCODE.indexOf(e.keyCode) === -1) {
                e.preventDefault()
            }
        },
        numbersOnlyEvent(e) {
            // we try and convert it with the '+' operator
            const val = +e.key
            // if it's not NaN, then we have a number key press
            if (isNaN(val) && ALLOWED_KEYCODE.indexOf(e.keyCode) === -1) {
                e.preventDefault()
            }
        }
    }
}

const FormConditionalChecks = {
    methods: {
        subscribeToDependencies(list) {
            for (let item of list) {
                if (item.dependsOnQuestionId) this.$root.$on(`edit-${item.dependsOnQuestionId}`, this.showQuestion)
            }
        },
        unsubscribe(list) {
            for (let item of list) {
                if (item.dependsOnQuestionId) this.$root.$off(`edit-${item.dependsOnQuestionId}`)
            }
        },
    }
}

export {
    FormBuilderMethods,
    FormRules,
    FormEvents,
    FormConditionalChecks
}
