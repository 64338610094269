<template>
    <component
        v-show="show"
        :is="renderForm"
        v-model="isValid"
        ref="formInput"
        :class="buttonFormClasses"
        lazy-validation
        class="c-editable-cell with-details"
        @submit.prevent
    >
        <div :class="labelClasses">
            {{label}}
        </div>
        <component
            :is="renderEditableComponent"
            :disabled="disabled"
            mandatory
            :class="{'presurvey': config.tiled, 'nps-scale': config.scale}"
            v-model="formInput.inputValue"
            :tile="tiled"
            color="primary"
            group
        >
            <v-btn
                v-for="(category, index) of radioGroupConfig.categories"
                :key="`category_${index}`"
                :value="category.value"
                x-large
            >
                {{ category.text }}
            </v-btn>
        </component>
    </component>
</template>

<script>
export default {
    name: 'ButtonGroup',
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        },
        editingForm: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: true
        },
        tiled: {
            type: Boolean,
            default: true
        },
        labelClasses: {
            type: String,
            default: 'text-sm text-black lg:text-base col-span-1 text-center mb-4 lg:mb-0 lg:text-left lg:col-span-2'
        }
    },
    data() {
        return {
            renderEditableComponent: 'v-btn-toggle',
            renderForm: 'v-form',
            isValid: true,
            formInput: {inputValue: ''},
            defaultRules: [],
            defaultButtons: [],
            defaultConfig: {categories: []}
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            this.$refs.formInput.reset()
            return this.isValid
        },
        editElement() {
            if (this.editingForm) this.$emit('click-triggered')
        },
    },
    computed: {
        radioGroupConfig() {
            return this.config || this.defaultConfig
        },
        buttons() {
            let buttons = this.config?.subComponents
            if (!buttons || !buttons.length) buttons = [...this.defaultButtons]

            return buttons
        },
        buttonFormClasses() {
            return this.config?.buttonFormClasses || this.defaultFormClasses
        }
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            this.$emit('input', this.formInput.inputValue)
        },
    }
}
</script>
